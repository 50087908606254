<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-6">
        <div class="col-6">
          <span class="text-gray-800 fs-6">Genera attestati a:</span>
          <div class="btn-group d-flex flex-column">
            <button
              type="button"
              class="badge bg-secondary rounded blueFit fs-5 mb-2"
              style="min-width: 300px"
              @click="puoGenerareQualifiche ? generaAttestatiTutti() : ''"
            >
              Tutti i partecipanti in lista</button
            ><button
              type="button"
              class="badge bg-secondary rounded blueFit fs-5"
              style="min-width: 300px"
              @click="puoGenerareQualifiche ? generaAttestatiSelezionati() : ''"
            >
              Solo i partecipanti selezionati
            </button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <template v-if="!puoGenerareQualifiche || !searchStatus">
      <div class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5">
        <span class="text-gray-700 ps-3 fs-2">
          Per procedere con la generazione, selezionare una qualifica dai filtri
        </span>
      </div>
    </template>
    <template v-else>
      <div class="mx-auto my-auto text-center" v-if="!loaded">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
      <div
        v-else-if="
          attestati_albo_list.length === 0 &&
          loaded &&
          (status == 200 || status == 204)
        "
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun risultato.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
          @click.prevent="$emit('resetFilters')"
        >
          <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
        ></span>
      </div>
      <div
        v-else-if="status != 200 && status != 204"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Si è verificato un errore. Riprovare più tardi.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
        >
          <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
            <i class="bi bi-house fs-2 text-gray-800"> </i
          ></router-link>
          <router-link v-else to="/bacheca-public">
            <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
        ></span>
      </div>
      <div v-else>
        <Datatable
          :table-header="tableHeader"
          :table-data="attestati_albo_list"
          :total="record"
          :current-page="currentPage"
          :rows-per-page="fetchRows"
          :sortLabel="sortColumn"
          :order="sortOrder"
          @sort="setSortOrderColumn"
          @items-per-page-change="setFetchRowsAttestatiAlbo"
          @current-change="setCurrentPageAttestatiAlbo"
        >
          <!-- @checkboxClicked="
          checkAll = !checkAll;
          attestati_albo_list.forEach((element) => {
            if (element.id_stato != 2) element.selected = checkAll;
          });
        " -->
          <!-- <template v-slot:cell-checkbox="{ row: data }">
          <input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
        /></template> -->
          <template v-slot:cell-nominativo_completo="{ row: data }">
            <router-link
              :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
              ><div
                class="tab-long"
                :class="data.no_qualifiche ? 'text-danger' : ''"
              >
                {{ data.nominativo_completo }}
                <span v-if="!data.nominativo_completo"> - </span>
              </div></router-link
            ></template
          >
          <template v-slot:cell-codice_snm="{ row: data }">
            <div :class="data.no_qualifiche ? 'text-danger' : ''">
              {{ data.codice_snm }}
              <span v-if="!data.codice_snm"> - </span>
            </div>
          </template>
          <template v-slot:cell-codice_dir="{ row: data }">
            <div :class="data.no_qualifiche ? 'text-danger' : ''">
              {{ data.codice_dir }}
              <span v-if="!data.codice_dir"> - </span>
            </div>
          </template>
          <template v-slot:cell-numero_albo="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                {{ qualifica.numero_albo }}
                <span v-if="!qualifica.numero_albo"> - </span>
              </template>
              <template v-else>
                {{ qualifica.numero_albo }}
                <span v-if="!qualifica.numero_albo"> - </span>
              </template>
            </div>
          </template>
          <template v-slot:cell-tipo_albo="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                {{ qualifica.albo }}
                <span v-if="!qualifica.albo"> - </span></template
              ><template v-else>
                {{ qualifica.albo }}
                <span v-if="!qualifica.albo"> - </span>
              </template>
            </div>
          </template>
          <template v-slot:cell-categoria_qualifica="{ row: data }">
            <div
              class="tab-long-xl my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <input
                  type="checkbox"
                  class="me-2"
                  @click="
                    qualifica.selected = !qualifica.selected
                      ? true
                      : !qualifica.selected
                  "
                />
                {{ qualifica.cql }}
                <span v-if="!qualifica.cql"> - </span>
              </template>
              <template v-else>
                <input
                  type="checkbox"
                  class="me-2"
                  @click="
                    qualifica.selected = !qualifica.selected
                      ? true
                      : !qualifica.selected
                  "
                />
                {{ qualifica.cql }}
                <span v-if="!qualifica.cql"> - </span>
              </template>
            </div>
          </template>
          <template v-slot:cell-data_acq="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <span v-if="qualifica.data_acq"
                  ><div class="text-center">
                    {{ getFormatDate(qualifica.data_acq) }}
                  </div></span
                >
                <span v-if="!qualifica.data_acq"> - </span>
              </template>
              <template v-else>
                <span v-if="qualifica.data_acq"
                  ><div class="text-center">
                    {{ getFormatDate(qualifica.data_acq) }}
                  </div></span
                >
                <span v-if="!qualifica.data_acq"> - </span>
              </template>
            </div>
          </template>
          <template v-slot:cell-data_decorrenza="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <span v-if="qualifica.data_dec"
                  ><div class="text-center">
                    {{ getFormatDate(qualifica.data_dec) }}
                  </div></span
                >
                <span v-if="!qualifica.data_dec"> - </span>
              </template>
              <template v-else>
                <span v-if="qualifica.data_dec"
                  ><div class="text-center">
                    {{ getFormatDate(qualifica.data_dec) }}
                  </div></span
                >
                <span v-if="!qualifica.data_dec"> - </span>
              </template>
            </div>
          </template>
          <template v-slot:cell-attestato_generato="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <span class="text-success" v-if="qualifica.attestato_generato"
                  >SI</span
                >
                <span class="text-danger" v-else>NO</span>
              </template>
              <template v-else>
                <span class="text-success" v-if="qualifica.attestato_generato"
                  >SI</span
                >
                <span class="text-danger" v-else>NO</span>
              </template>
            </div>
          </template>
          <template v-slot:cell-scaricato="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <span class="text-success" v-if="qualifica.scaricato">SI</span>
                <span class="text-danger" v-else>NO</span>
              </template>
              <template v-else>
                <span class="text-success" v-if="qualifica.scaricato">SI</span>
                <span class="text-danger" v-else>NO</span>
              </template>
            </div>
          </template>
          <template v-slot:cell-inviato="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <span class="text-success" v-if="qualifica.inviato">SI</span>
                <span class="text-danger" v-else>NO</span>
              </template>
              <template v-else>
                <span class="text-success" v-if="qualifica.inviato">SI</span>
                <span class="text-danger" v-else>NO</span>
              </template>
            </div>
          </template>
          <template v-slot:cell-data_ora_dw="{ row: data }">
            <div
              class="my-2"
              :class="data.no_qualifiche ? 'text-danger' : ''"
              v-for="qualifica in data.qualifiche"
              :key="qualifica.id_albo"
            >
              <template
                v-if="
                  idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
                "
              >
                <span v-if="qualifica.data_ora_download">
                  {{ qualifica.data_ora_download }}</span
                >
                <span v-if="!qualifica.data_ora_download"> - </span>
              </template>
              <template v-else>
                <span v-if="qualifica.data_ora_download">
                  {{ qualifica.data_ora_download }}</span
                >
                <span v-if="!qualifica.data_ora_download"> - </span>
              </template>
            </div>
          </template>

          <template v-slot:cell-options="{ row: data }">
            <div class="btn-group">
              <button
                class="btn btn-sm dropdown p-1"
                @click="
                  getCorsiAggiornamentoSnm(data.id_persona);
                  selectedPersona = data.nominativo_completo;
                "
              >
                <a
                  href="#"
                  class="navi-link"
                  data-bs-toggle="modal"
                  data-bs-target="#corsi_aggiornamento_snm"
                >
                  <i class="bi bi-bookmark-check text-dark fs-5"></i>
                </a>
              </button>
              <!-- v-if="data.id_tipologia_corso == 9" -->
              <button
                class="btn btn-sm dropdown p-1"
                @click="generaAttestatiPersona(data.qualifiche)"
              >
                <i class="bi bi-card-heading text-dark fs-4"></i>
              </button>
            </div>
          </template>
        </Datatable>
      </div>
    </template>

    <SelectEditElemento
      :albo="selectedAttestatiAlbo"
      :persona="selectedPersona"
      :isLoadingDetail="isLoadingDetail"
    />
    <DeleteQualifiche
      :albo="selectedAttestatiAlbo"
      :persona="selectedPersona"
      @refreshAlboList="refreshAttestatiAlboList"
      :isLoadingDetail="isLoadingDetail"
    />
    <AddUtenzaAlbo
      :persona="personaUtenza"
      @refreshAlboList="refreshAttestatiAlboList"
    />
    <ListaCorsiAggiornamentoSnm
      :nominativo_persona="selectedPersona"
      :corsi_aggiornamento_snm_list="corsi_aggiornamento_snm_list"
      :loadingDetail="loadingDetail"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed, getCurrentInstance } from "vue";
import { viewCorsiAggiornamentoList } from "@/requests/alboInsegnateRequests";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";

import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { alertChoice, alertFailed, alertSuccess } from "@/composables/swAlert";

import ListaCorsiAggiornamentoSnm from "@/components/components-fit/SNM/albo-snm/ListaCorsiAggiornamentoSnm.vue";
import services from "@/axios/dbManag";
export default {
  name: "TableAlbo",
  components: {
    Datatable,
    Loading,

    ListaCorsiAggiornamentoSnm,
  },
  emits: ["getAttestatiAlboList", "resetFilters"],
  setup(props, { emit }) {
    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const store = useStore();

    const setFetchRowsAttestatiAlbo = (e) => {
      store.commit("setFetchRowsAttestatiAlbo", e);
      emit("getAttestatiAlboList");
    };
    const setCurrentPageAttestatiAlbo = (page) => {
      store.commit("setCurrentPageAttestatiAlbo", page);
      emit("getAttestatiAlboList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAttestatiAlbo", columnName);
      store.commit("setSortOrderAttestatiAlbo", order);
      emit("getAttestatiAlboList");
    };

    const refreshAttestatiAlboList = () => {
      emit("getAttestatiAlboList");
    };

    const isLoadingDetail = ref(false);

    const selectedAttestatiAlbo = ref({});
    const selectedPersona = ref("");

    const isLoading = ref(false);

    /* const addSgat = (id_Persona) => {
      isLoading.value = true;
      addPermessiSgat(id_Persona).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          alertSuccess("Permessi SGAT assegnati");
          emit("getDocumentiSocieta");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    }; */

    const promuoviMaestroNew = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il tesserato verrà promosso maestro.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Promuovi",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
        }
      });
    };

    const personaUtenza = ref(null);

    const loadingDetail = ref(false);
    const corsi_aggiornamento_snm_list = ref(null);
    const getCorsiAggiornamentoSnm = (id_persona) => {
      loadingDetail.value = true;
      viewCorsiAggiornamentoList(id_persona).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          corsi_aggiornamento_snm_list.value = res.data.results;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "corsi_aggiornamento_snm"
          );
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Persona",
        key: "nominativo_completo",
      },
      {
        name: "Cod. SNM",
        key: "codice_snm",
        sortable: false,
      },
      {
        name: "Cod. DIR",
        key: "codice_dir",
        sortable: false,
      },
      {
        name: "N. Albo",
        key: "numero_albo",
        sortable: false,
      },
      {
        name: "Tipo Albo",
        key: "tipo_albo",
        sortable: false,
      },
      {
        name: "Categoria / Qualifica / Livello",
        key: "categoria_qualifica",
        sortable: false,
      },
      {
        name: "Data acq.",
        key: "data_acq",
        align: "center",
      },
      {
        name: "Data dec.",
        key: "data_decorrenza",
        sortable: false,
        align: "center",
      },

      {
        name: "Attestato generato",
        key: "attestato_generato",
        sortable: false,
      },
      {
        name: "Attestato scaricato",
        key: "scaricato",
        sortable: false,
      },
      {
        name: "Inviato",
        key: "inviato",
        sortable: false,
      },
      {
        name: "Data/Ora Download",
        key: "data_ora_dw",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const attestati_albo_list = computed(() =>
      store.getters.getStateFromName("resultsattestati_albo_list")
    );

    const generaAttestatoQualificaAPI = async (payload) => {
      isLoading.value = true;
      await services.dbManag
        .post(
          globalApi.ALBI_PERSONE_QUALIFICHE_DOWNLOAD_DISPONIBILE_ADD,
          payload
        )
        .then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            alertSuccess("Attestati generati con successo");
            emit("getAttestatiAlboList");
          }
          return res.data.result ? res.data.result : res.data;
        })
        .catch((e) => {
          isLoading.value = false;

          alertFailed(
            e.response.data.message
              ? e.response.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        });
    };

    const generaAttestatiSelezionati = async () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Continuando verranno generati gli attestati solo per le qualifiche selezionate",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Continua",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        } else {
          let qualificheSelezionate = "";
          attestati_albo_list.value.forEach((albo) => {
            albo.qualifiche.forEach((qualifica) => {
              if (qualifica.selected) {
                qualificheSelezionate =
                  qualificheSelezionate + `${qualifica.id_albo},`;
              }
            });
          });
          if (!qualificheSelezionate.length) {
            alertFailed("Nessuna qualifica selezionata");
            return;
          }
          await generaAttestatoQualificaAPI({
            id_arr_albi_qualifiche: qualificheSelezionate,
          });
        }
      });
    };
    const generaAttestatiTutti = async () => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Continuando verranno generati gli attestati a <b>TUTTE</b> le qualifiche in lista",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Continua",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        } else {
          let qualificheSelezionate = "";
          attestati_albo_list.value.forEach((albo) => {
            albo.qualifiche.forEach((qualifica) => {
              qualificheSelezionate =
                qualificheSelezionate + `${qualifica.id_albo},`;
            });
          });
          await generaAttestatoQualificaAPI({
            id_arr_albi_qualifiche: qualificheSelezionate,
          });
        }
      });
    };

    const idQualificaAlbo = computed(
      () => store.getters.idQualificaAlboAttestatiAlbo
    );

    const generaAttestatiPersona = async (qualifiche) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Continuando verrà generato l'attestato per la persona selezionata",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Continua",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        } else {
          let qualificheSelezionate = "";
          qualifiche.forEach((qualifica) => {
            if (qualifica.id_qualifica == idQualificaAlbo.value) {
              qualificheSelezionate =
                qualificheSelezionate + `${qualifica.id_albo},`;
            }
          });
          await generaAttestatoQualificaAPI({
            id_arr_albi_qualifiche: qualificheSelezionate,
          });
        }
      });
    };

    return {
      generaAttestatiPersona,
      generaAttestatiSelezionati,
      generaAttestatiTutti,
      getFormatDate,
      attestati_albo_list,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedattestati_albo_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordattestati_albo_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusattestati_albo_list")
      ),
      refreshAttestatiAlboList,
      currentPage: computed(() => store.getters.currentPageAttestatiAlbo),
      rowsToSkip: computed(() => store.getters.rowsToSkipAttestatiAlbo),
      fetchRows: computed(() => store.getters.fetchRowsAttestatiAlbo),
      sortColumn: computed(() => store.getters.sortColumnAttestatiAlbo),
      sortOrder: computed(() => store.getters.sortOrderAttestatiAlbo),
      setFetchRowsAttestatiAlbo,
      setCurrentPageAttestatiAlbo,
      setSortOrderColumn,
      tableHeader,
      selectedAttestatiAlbo,
      personaUtenza,
      selectedPersona,
      //addSgat,
      getCorsiAggiornamentoSnm,
      promuoviMaestroNew,
      isLoading,
      isLoadingDetail,
      loadingDetail,
      corsi_aggiornamento_snm_list,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      idTipoAlbo: computed(() => store.getters.idTipoAlboAttestatiAlbo),
      idCategoriaAlbo: computed(
        () => store.getters.idCategoriaAlboAttestatiAlbo
      ),
      idQualificaAlbo,
      dataDecDaAttestatiAlbo: computed(
        () => store.getters.dataDecDaAttestatiAlbo
      ),
      dataDecAAttestatiAlbo: computed(
        () => store.getters.dataDecAAttestatiAlbo
      ),
      searchStatus: computed(() => store.getters.searchStatusAttestatiAlbo),
      id_persona: computed(() => store.getters.id_personaAttestatiAlbo),
    };
  },
  computed: {
    puoGenerareQualifiche() {
      return this.id_persona ? this.id_persona : this.idQualificaAlbo;
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
